import React, { Component } from "react";
import { MdWork } from 'react-icons/md';

//STYLES//
import './App.css';

//LIBRARIES//
import styled from 'styled-components'


//COMPONENTS//
import About from './Views/about';
import Footer from './Views/footer';
import Header from './Views/header';
import Intro from './Views/intro';
import Projects from './Views/projects';
import Skills from './Views/skills'
import Modal from './Components/modal';

const AppStyle = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	overflow-x: hidden;
`;
const LinkArea = styled.div`
	width: 100%,
	top: 
`;

const ExperienceHeader = styled.div`
	margin-top: 30px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	font-size: 36px;
	font-family: 'Roboto', sans-serif;
	gap: 15px;
	@media (max-width: 1000px) {
		font-size: 28px;
	};
	@media (max-width: 800px) {
		font-size: 22px;
	};
`;

const ExperienceWrapper = styled.div`
	width: calc(100% - 60px);
	max-width: 1300px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 30px;
`;

const ExperienceCard = styled.div`
	background: white;
	border-radius: 8px;
	padding: 25px;
	margin: 15px 0;
	box-shadow: 0 2px 15px rgba(0,0,0,0.1);
	transition: all 0.3s ease;
	width: 100%;
	&:hover {
		transform: translateY(-5px);
		box-shadow: 0 5px 20px rgba(0,0,0,0.15);
	}
`;

const ExperienceDate = styled.div`
	color: #B2D4F5;
	font-weight: bold;
	font-size: 16px;
	margin-bottom: 10px;
`;

const ExperienceRole = styled.h3`
	color: #2e3e4e;
	font-size: 24px;
	margin: 0 0 10px 0;
	@media (max-width: 800px) {
		font-size: 20px;
	}
`;

const ExperienceCompany = styled.div`
	color: #2e3e4e;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 15px;
	@media (max-width: 800px) {
		font-size: 18px;
	}
`;

const ExperienceDescription = styled.div`
	color: #4a5568;
	font-size: 16px;
	line-height: 1.6;
	@media (max-width: 800px) {
		font-size: 14px;
	}
`;

const ExperienceSection = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

class App extends Component{
	constructor(props){
		super(props);
		this.state = {
			aboutAnimation: false,
			skillsAnimation: false,
			projectsAnimation: false,
			modal: false,
			modalText: null,
			modalImages: null
		}
	};

	componentDidMount(){
		document.addEventListener('scroll', () => this.isScrolledIntoView())
	};

	componentDidUpdate(prevState){
		if(this.state.modal && this.state.modal !== prevState.modal){
			document.body.style.overflowY = "hidden"
		}
		else if(!this.state.modal && this.state.modal !== prevState.modal){
			document.body.style.overflowY = "auto"
		}
	};

	handleModal(modal, text, images){
		this.setState({
		modal: modal,
		modalText: text,
		modalImages: images
		});
	};


	isScrolledIntoView() {
		var about = document.getElementById('About1')
		var aboutRect = about.getBoundingClientRect();
		var elemTop = aboutRect.top;
		var elemBottom = aboutRect.bottom;
		var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
		if(isVisible && !this.state.aboutAnimation){
			this.setState({
				aboutAnimation: true
			});
		};
		var skills = document.getElementById('Skills1')
		var skillsRect = skills.getBoundingClientRect();
		var elemTop2 = skillsRect.top;
		var elemBottom2 = skillsRect.bottom;
		var isVisible2 = (elemTop2 >= 0) && (elemBottom2 <= window.innerHeight);
		if(isVisible2 && !this.state.skillsAnimation){
			this.setState({
				skillsAnimation: true
			});
		}
		var projects = document.getElementById('Projects1')
		var projectsRect = projects.getBoundingClientRect();
		var elemTop3 = projectsRect.top;
		var elemBottom3 = projectsRect.bottom;
		var isVisible3 = (elemTop3 >= 0) && (elemBottom3 <= window.innerHeight);
		if(isVisible3 && !this.state.projectsAnimation){
			this.setState({
				projectsAnimation: true
			});
		}
	}

  render(){
	return (
		<AppStyle className="App">
			<Header modal={this.state.modal}/>
			<Intro/>
			<LinkArea id="Projects"/>
			<ExperienceSection>
				<LinkArea id="Experience1"/>
				<ExperienceHeader>
					<MdWork size={25} /> Experience <MdWork size={25} />
				</ExperienceHeader>
				
				<ExperienceWrapper>
					<ExperienceCard>
						<ExperienceDate>2021 - Present</ExperienceDate>
						<ExperienceRole>Lead Software Engineer</ExperienceRole>
						<ExperienceCompany>Lively Video</ExperienceCompany>
						<ExperienceDescription>
						Design and architect an SDK and API specifically built for live video applications. I transitioned from an individual contributor to leading a team of four talented senior engineers, fostering a collaborative environment focused on delivering high-quality, scalable solutions. This includes managing bi-weekly agile sprints, defining deliverables, setting timelines, and contributing to strategic planning. I also spearhead the development of an internal testing tool with real world example pages, making it easy to validate and debug the SDK and API. A key part of this role involves working directly with customers to integrate our technology into their products, offering hands-on support and troubleshooting. Notably, I oversaw the successful integration of two major customer products, guiding their transition from legacy systems to our modern platform.
						</ExperienceDescription>
					</ExperienceCard>

					<ExperienceCard>
						<ExperienceDate>2019 - 2021</ExperienceDate>
						<ExperienceRole>Senior Software Engineer</ExperienceRole>
						<ExperienceCompany>Oregon State University</ExperienceCompany>
						<ExperienceDescription>
						Developed engaging, accessible, and responsive web applications using React, Redux, HTML, CSS, and TypeScript to support online learning within Oregon State ECampus. Led front-end architecture planning, mentored other developers, and played a key role in guiding project direction. Co-led the agile development process using Scrum, driving collaboration and efficiency across the team by centralizing communication and setting clear goals. My work extended beyond the front end—leveraging AWS services like Lambda, DynamoDB, S3, and CodePipeline for full-stack development and deployment. Contributed to the development of multiple mobile apps with React Native and the design of several educational websites aimed at helping students achieve meaningful learning outcomes.
						</ExperienceDescription>
					</ExperienceCard>
				</ExperienceWrapper>
			</ExperienceSection>
			<Projects animateProjects={this.state.projectsAnimation} handleModal={(modal, text, image) => this.handleModal(modal, text, image)} modal={this.state.modal}/>
			<LinkArea id="About"/>
			<About animateAbout={this.state.aboutAnimation}/>
			<LinkArea id="Skills"/>
			<Skills animateSkills={this.state.skillsAnimation}/>
			<Footer modal={this.state.modal}/>
			{
				this.state.modal &&
				<Modal images={this.state.modalImages} text={this.state.modalText} handleModal={(modal, text, image) => this.handleModal(modal, text, image)}/>
			}
		</AppStyle>
	  );
  }
};

export default App;
