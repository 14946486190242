import React, { Component } from "react";

//COMPONENTS//
import Link from '../Components/headerLinks'

//LIBRARIES//
import styled from 'styled-components'

//ASSETS//
import headers from '../Assets/headers.json';

const Wrapper = styled.div`
    z-index: 2;
    width: 100%;
    height: 50px;
    background: #2e3e4e;
    top: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
`

class Header extends Component {
    constructor(props){
        super(props);
        this.state = {
            active: null
        };
    };

    componentDidMount(){
    };

    setActive(activeLink){
        if(activeLink !== this.state.active){
            this.setState({
                active: activeLink
            });
        };
    };
    
    render() {
        return(
            <Wrapper>
                <Link 
                    modal={this.props.modal} 
                    key="Experience" 
                    active={this.state.active} 
                    name="Experience" 
                    setActive={(link) => this.setActive(link)}
                />
                {
                    headers.map((link) =>
                        <Link 
                            modal={this.props.modal} 
                            key={link} 
                            active={this.state.active} 
                            name={link} 
                            setActive={(link) => this.setActive(link)}
                        />
                    )
                }
            </Wrapper>
        );
    };
};

export default(Header);