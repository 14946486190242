import React, { Component } from "react";

//LIBRARIES//
import styled from 'styled-components'

//COMPONENTS//
import SkillsHeaders from '../Components/Headers/skillsHeader';

const Wrapper = styled.div`
    margin: 30px;
    width: calc(100% - 60px);
    display: flex;
    justify-content: center;
    flex-direction: column
`;

const GraphWrapper = styled.div`
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const SkillWrapper = styled.div`
	position: relative;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	justify-content: left;
	align-items: center;
	flex-direction: row;
	width: 700px;
	@media (max-width: 1000px) {
        width: 550px;
    };
    @media (max-width: 800px) {
        width: 400px;
    };
`;

const JavaScriptLine = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 4px 4px 0px;
	height: 30px;
	width: 585px;
	margin-left: 115px;
	background: #2e3e4e;
	transition: width 0.5s ease-in-out;
	@media (max-width: 1000px) {
		margin-left: 100px;
        width: 450px;
    };
    @media (max-width: 800px) {
		margin-left: 85px;
        width: 315px;
    };
`;

const CssLine = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 4px 4px 0px;
	height: 30px;
	width: 527px;
	margin-left: 115px;
	background: #2e3e4e;
	transition: width 0.5s ease-in-out;
	@media (max-width: 1000px) {
		margin-left: 100px;
        width: 405px;
    };
    @media (max-width: 800px) {
		margin-left: 85px;
        width: 283px;
    };
`;

const HtmlLine = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 4px 4px 0px;
	height: 30px;
	width: 527px;
	margin-left: 115px;
	background: #2e3e4e;
	transition: width 0.5s ease-in-out;
	@media (max-width: 1000px) {
		margin-left: 100px;
        width: 405px;
    };
    @media (max-width: 800px) {
		margin-left: 85px;
        width: 283px;
    };
`;

const ReactLine = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 4px 4px 0px;
	height: 30px;
	width: 585px;
	margin-left: 115px;
	background: #2e3e4e;
	transition: width 0.5s ease-in-out;
	@media (max-width: 1000px) {
		margin-left: 100px;
        width: 450px;
    };
    @media (max-width: 800px) {
		margin-left: 85px;
        width: 315px;
    };
`;

const ReduxLine = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 4px 4px 0px;
	height: 30px;
	width: 585px;
	margin-left: 115px;
	background: #2e3e4e;
	transition: width 0.5s ease-in-out;
	@media (max-width: 1000px) {
		margin-left: 100px;
        width: 450px;
    };
    @media (max-width: 800px) {
		margin-left: 85px;
        width: 315px;
    };
`;

const TypescriptLine = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 4px 4px 0px;
	height: 30px;
	width: 439px;
	margin-left: 115px;
	background: #2e3e4e;
	transition: width 0.5s ease-in-out;
	@media (max-width: 1000px) {
		margin-left: 100px;
        width: 337px;
    };
    @media (max-width: 800px) {
		margin-left: 85px;
        width: 236px;
    };
`;

const NodejsLine = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 4px 4px 0px;
	height: 30px;
	width: 439px;
	margin-left: 115px;
	background: #2e3e4e;
	transition: width 0.5s ease-in-out;
	@media (max-width: 1000px) {
		margin-left: 100px;
        width: 337px;
    };
    @media (max-width: 800px) {
		margin-left: 85px;
        width: 236px;
    };
`;

const AwsLine = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 4px 4px 0px;
	height: 30px;
	width: 439px;
	margin-left: 115px;
	background: #2e3e4e;
	transition: width 0.5s ease-in-out;
	@media (max-width: 1000px) {
		margin-left: 100px;
        width: 337px;
    };
    @media (max-width: 800px) {
		margin-left: 85px;
        width: 236px;
    };
`;

const PhotoshopLine = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 4px 4px 0px;
	height: 30px;
	width: 292px;
	margin-left: 115px;
	background: #2e3e4e;
	transition: width 0.5s ease-in-out;
	@media (max-width: 1000px) {
		margin-left: 100px;
		width: 225px;
    };
    @media (max-width: 800px) {
		margin-left: 85px;
		width: 157px;
    };
`;

const IllustratorLine = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 4px 4px 0px;
	height: 30px;
	width: 292px;
	margin-left: 115px;
	background: #2e3e4e;
	transition: width 0.5s ease-in-out;
	@media (max-width: 1000px) {
		margin-left: 100px;
		width: 225px;
    };
    @media (max-width: 800px) {
		margin-left: 85px;
		width: 157px;
    };
`;

const Text = styled.div`
	position: absolute;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 115px;
	font-size: 20px;
	height: 30px;
	border-radius: 4px 0px 0px 4px;
	font-family: 'Roboto', sans-serif;
	color: #B2D4F5;
	background: #2e3e4e;
	text-align: center;
	transition: all 0.3s ease-in-out;
	@media (max-width: 1000px) {
		font-size: 16px;
		width: 100px;
    };
    @media (max-width: 800px) {
		font-size: 14px;
		width: 85px;
    };
`;

const PercentText = styled.div`
	font-size: 20px;
	font-family: 'Roboto', sans-serif;
	color: #2e3e4e;
	transition: opacity 1s ease-in-out, font-size 0.3s ease-in-out;
	@media (max-width: 1000px) {
        font-size: 16px;
    };
    @media (max-width: 800px) {
        font-size: 14px;
    };
`;


const LinkArea = styled.div`
	width: 100%,
	top: 
`;



class Skills extends Component {

	CssTimeout;
    HtmlTimeout;
	JavaScriptTimeout;
	ReduxTimeout;
	TypescriptTimeout;
	NodejsTimeout;
	AwsTimeout;
	PhotoshopTimeout;
	IllustratorTimeout;
	

    constructor(props){
        super(props);
        this.state = {
			JavascriptTimeout: false,
			CssTimeout: false,
			HtmlTimeout: false,
			ReactTimeout: false,
			ReduxTimeout: false,
			TypescriptTimeout: false,
			NodejsTimeout: false,
			AwsTimeout: false,
			PhotoshopTimeout: false,
			IllustratorTimeout: false
        }
    };

    componentDidMount(){
    };

	componentDidUpdate(prevProps){
		if(this.props.animateSkills && this.props.animateSkills !== prevProps.animateSkills){
			this.handleLineWidths();
		}
	};

    componentWillUnmount(){
        clearTimeout( this.CssTimeout, this.HtmlTimeout, this.JavaScriptTimeout, this.ReduxTimeout, this.TypescriptTimeout, this.NodejsTimeout, this.AwsTimeout, this.PhotoshopTimeout, this.IllustratorTimeout);
    };

    handleLineWidths(){
		this.setState({
			ReactTimeout:true
		});
				
		this.ReduxTimeout = setTimeout(() => {
            this.setState({
                ReduxTimeout:true
            });
		}, 250);

        this.JavaScriptTimeout = setTimeout(() => {
			this.setState({
				JavascriptTimeout: true
			});
		}, 500);
		
        this.CssTimeout = setTimeout(() => {
            this.setState({
                CssTimeout:true
            });
        }, 750);

        this.HtmlTimeout = setTimeout(() => {
            this.setState({
                HtmlTimeout:true
            });
        }, 1000);

        this.TypescriptTimeout = setTimeout(() => {
            this.setState({
                TypescriptTimeout:true
            });
        }, 1250);

        this.NodejsTimeout = setTimeout(() => {
            this.setState({
                NodejsTimeout:true
            });
		}, 1500);
		
		this.AwsTimeout = setTimeout(() => {
            this.setState({
                AwsTimeout:true
            });
        }, 1750);

        this.PhotoshopTimeout = setTimeout(() => {
            this.setState({
                PhotoshopTimeout:true
            });
        }, 2000);

        this.IllustratorTimeout = setTimeout(() => {
            this.setState({
                IllustratorTimeout:true
            });
        }, 2250);
	};
	

  render() {
    return(
        <Wrapper>
            <SkillsHeaders/>
			<LinkArea id="Skills1"/>
			<GraphWrapper>
				<SkillWrapper>
					<Text>
						React
					</Text>
					<ReactLine style={{width: this.state.ReactTimeout ? null : 0}}>
					</ReactLine>
				</SkillWrapper>
				<SkillWrapper>
					<Text>
						Redux
					</Text>
					<ReduxLine style={{width: this.state.ReduxTimeout ? null : 0}}>
					</ReduxLine>
				</SkillWrapper>
				<SkillWrapper>
					<Text>
						JavaScript
					</Text>
					<JavaScriptLine style={{width: this.state.JavascriptTimeout ? null : 0}}>
					</JavaScriptLine>
				</SkillWrapper>
				<SkillWrapper>
					<Text>
						CSS
					</Text>
					<CssLine style={{width: this.state.CssTimeout ? null : 0}}>
					</CssLine>
				</SkillWrapper>
				<SkillWrapper>
					<Text>
						HTML
					</Text>
					<HtmlLine style={{width: this.state.HtmlTimeout ? null : 0}}>
					</HtmlLine>
				</SkillWrapper>
				<SkillWrapper>
					<Text>
						TypeScript
					</Text>
					<TypescriptLine style={{width: this.state.TypescriptTimeout ? null : 0}}>
					</TypescriptLine>
				</SkillWrapper>
				<SkillWrapper>
					<Text>
						Node.js
					</Text>
					<NodejsLine style={{width: this.state.NodejsTimeout ? null : 0}}>
					</NodejsLine>
				</SkillWrapper>
				<SkillWrapper>
					<Text>
						AWS
					</Text>
					<AwsLine style={{width: this.state.AwsTimeout ? null : 0}}>
					</AwsLine>
				</SkillWrapper>
				<SkillWrapper>
					<Text>
						PhotoShop
					</Text>
					<PhotoshopLine style={{width: this.state.PhotoshopTimeout ? null : 0}}>
					</PhotoshopLine>
				</SkillWrapper>
				<SkillWrapper>
					<Text id="SkillsContent">
						Illustrator
					</Text>
					<IllustratorLine style={{width: this.state.IllustratorTimeout ? null : 0}}>
					</IllustratorLine>
				</SkillWrapper>

			</GraphWrapper>
        </Wrapper>
    );
  }
}

export default(Skills);