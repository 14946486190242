import React, { Component } from "react";
import { MdRocketLaunch } from 'react-icons/md';  // Changed to rocket icon

//LIBRARIES//
import styled from 'styled-components'

const Wrapper = styled.div`
	margin-top: 30px;	
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
`;

const Text = styled.div`
	font-size: 36px;
	margin-left: 15px;
	margin-right: 15px;
	font-family: 'Roboto', sans-serif;
	@media (max-width: 1000px) {
        font-size: 28px;
        margin-bottom: 5px;
        margin-top: 10px
    };
    @media (max-width: 800px) {
        font-size: 22px;
    };
`;

class ProjectsHeader extends Component {
	render() {
		return(
			<Wrapper>
				<MdRocketLaunch size={25} />
				<Text>Projects</Text>
				<MdRocketLaunch size={25} />
			</Wrapper>
		);
	}
}

export default ProjectsHeader;